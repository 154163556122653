import React, { useEffect, useState, useRef } from "react";
import Pagination from "react-bootstrap/Pagination";
import Share from "./Share";

function BlogList({ json }) {
  const ref = useRef(null);
  const [state, setState] = useState({
    data: [],
    limit: 6,
  });
  //const [limit, setLimit] = useState(6);
  const [activePage, setActivePage] = useState(1);
  const [listData, setListData] = useState([]);
  const listDataRef = useRef(listData); // Ref to track the latest listData
  const updateLimit = () => {
    const isMobile = window.matchMedia("(max-width: 768px)").matches;
    const newLimit = isMobile ? 3 : 6; // 3 for mobile, 6 for larger screens

    setListData((prevListData) => {
      setState((prevState) => ({ ...prevState, limit: newLimit }));
      const out = prevListData.slice(0, newLimit);
      setState((prev) => ({
        ...prev,
        data: out,
      }));
      return prevListData; // Return the same value since we're not updating it here
    });
  };
  useEffect(() => {
    updateLimit();
    /*window.addEventListener("resize", updateLimit);
    return () => {
      window.removeEventListener("resize", updateLimit); // Clean up listener on unmount
    };*/
  }, []);
  useEffect(() => {
    if (listData && listData.length == 0) {
      fetch(`${json}?r=${Date.now()}`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
        .then(function (response) {
          return response.json();
        })
        .then(function (myJson) {
          const isMobile = window.matchMedia("(max-width: 768px)").matches;
          const newLimit = isMobile ? 3 : 6; // 3 for mobile, 6 for larger screens
          setListData(myJson);
          const out = myJson.slice(0, newLimit);
          setState((prev) => ({
            ...prev,
            data: out,
          }));
        });
    }

    // Cleanup listener on component unmount
    // return () => window.removeEventListener("resize", updateLimit);
  }, []);

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
    const offset = pageNumber * state.limit;
    const out = listData.slice(offset - state.limit, offset);
    setState((prev) => ({
      ...prev,
      data: out,
    }));
    ref.current?.scrollIntoView({ behavior: "smooth" });
    //setState((prev) => ({ ...prev, activePage: pageNumber }));
  };

  const getPageLink = (item) => {
    if (item.link) {
      return item.link;
    }
    return `./blogs/${item.id}`;
  };
  //console.log("state.limit >> ", state.limit);
  //console.log("state.data >> ", state.data);
  //console.log(listData.length / state.limit);
  return (
    <section className="container blog-posts" ref={ref}>
      <div className="row mt-2">
        {state.data.length > 0 &&
          state.data.map((item, index) => {
            return [
              index % 2 === 0 ? (
                <article className="blog-post" key={index}>
                  <div className="blog-post-container row">
                    <div className="blog-post-image col-md-4 col-sm-12">
                      <img src={item.image} alt={item.alt_text} />
                    </div>
                    <div className="blog-post-content col-md-8 col-sm-12">
                      <div className="blog-post-header">
                        <h2>{item.title}</h2>
                        <div className="blog-post-meta">
                          <img src="./assets/images/ico-calendar.png"></img>
                          <span className="date">{item.date}</span>
                        </div>
                      </div>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: item.short_description,
                        }}
                      />
                      {item.reading_time ? (
                        <div className="d-flex justify-content-start flex-wrap">
                          <p>
                            <img
                              style={{ width: "1em", marginBottom: "1em" }}
                              src="./assets/images/ico-timer.svg"
                            ></img>
                          </p>
                          &nbsp;&nbsp;
                          <div
                            dangerouslySetInnerHTML={{
                              __html: item.reading_time,
                            }}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                      <a
                        href={getPageLink(item)}
                        target={item.link ? "_blank" : "_self"}
                        className="btn btn-primary read-more-btn gtm-lp gtm-blog-read-more"
                      >
                        READ MORE
                      </a>
                      &nbsp;&nbsp;
                      <div className="share-container blog-list">
                        <Share
                          url={`./blogs/${item.id}`}
                          msg={"Watch%20Now%20On%20Fawesome"}
                        ></Share>
                      </div>
                      {/* <a
                        href={getPageLink(item)}
                        target={item.link ? "_blank" : "_self"}
                        className="btn btn-primary read-more-btn gtm-lp gtm-blog-read-more"
                      >
                        READ MORE
                      </a> */}
                    </div>
                  </div>
                </article>
              ) : (
                <article className="blog-post" key={index}>
                  <div className="blog-post-container row">
                    <div className="blog-post-content col-md-8 col-sm-12">
                      <div className="blog-post-header">
                        <h2>{item.title}</h2>
                        <div className="blog-post-meta">
                          <img src="./assets/images/ico-calendar.png"></img>
                          <span className="date">{item.date}</span>
                        </div>
                      </div>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: item.short_description,
                        }}
                      />
                      {/* <div className="d-flex justify-content-start flex-wrap">
                        <p>
                          <img
                            style={{ width: "1em", marginBottom: "1em" }}
                            src="./assets/images/ico-timer.svg"
                          ></img>
                        </p>
                        &nbsp;&nbsp;
                        <div
                          dangerouslySetInnerHTML={{
                            __html: item.reading_time,
                          }}
                        />
                      </div> */}
                      {item.reading_time ? (
                        <div className="d-flex justify-content-start flex-wrap">
                          <p>
                            <img
                              style={{ width: "1em", marginBottom: "1em" }}
                              src="./assets/images/ico-timer.svg"
                            ></img>
                          </p>
                          &nbsp;&nbsp;
                          <div
                            dangerouslySetInnerHTML={{
                              __html: item.reading_time,
                            }}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                      <a
                        href={getPageLink(item)}
                        target={item.link ? "_blank" : "_self"}
                        className="btn btn-primary read-more-btn gtm-lp gtm-blog-read-more"
                      >
                        READ MORE
                      </a>
                      &nbsp;&nbsp;
                      <div className="share-container blog-list">
                        <Share
                          url={`./blogs/${item.id}`}
                          msg={"Watch%20Now%20On%20Fawesome"}
                        ></Share>
                      </div>
                    </div>
                    <div className="blog-post-image col-md-4 col-sm-12">
                      <img src={item.image} alt={item.alt_text} />
                    </div>
                  </div>
                </article>
              ),
            ];
          })}
      </div>
      {Array(Math.round(listData.length / state.limit)).length > 0 && (
        <Pagination className="px-4 d-flex justify-content-center">
          <Pagination.Prev
            disabled={activePage > 1 ? false : true}
            onClick={() => handlePageChange(activePage - 1)}
          />
          {[...Array(Math.ceil(listData.length / state.limit))].map(
            (_, index) => {
              return (
                <Pagination.Item
                  onClick={() => handlePageChange(index + 1)}
                  key={index + 1}
                  active={index + 1 === activePage}
                >
                  {index + 1}
                </Pagination.Item>
              );
            }
          )}
          <Pagination.Next
            disabled={
              activePage < Math.round(listData.length / state.limit)
                ? false
                : true
            }
            onClick={() => handlePageChange(activePage + 1)}
          />
        </Pagination>
      )}
    </section>
  );
}
export default BlogList;
