import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import HomePage from "./pages/HomePage";
import TitlesPage from "./pages/TitlesPage";
import ErrorPage from "./pages/ErrorPage";
import BlogsPage from "./pages/BlogsPage";
import PressReleasesPage from "./pages/PressReleasesPage";
import MoviePage from "./pages/MoviePage";
import PartnershipPage from "./pages/PartnershipPage";
import BlogInner from "./components/BlogInner";
// import ArtistForm from "./components/ArtistForm";
import { useEffect } from "react";
const RedirectToHome = () => {
  useEffect(() => {
    window.location.href = "https://website.freeawesometv.com/home/";
  }, []);
  return null;
};

const RedirectToHomeIndex = () => {
  useEffect(() => {
    window.location.href = "https://website.freeawesometv.com/home/index.php";
  }, []);
  return null;
};

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        {/* <Route path="/" element={<RedirectToHome />} /> */}
        <Route path="/about" element={<HomePage />} />
        {/* <Route path="/titles" element={<TitlesPage></TitlesPage>} /> */}
        <Route path="/blogs" element={<BlogsPage />} />
        <Route path="/errorpage" element={<ErrorPage></ErrorPage>} />
        <Route path="/blogs/:id" element={<BlogInner />} />
        <Route path="/partnerships" element={<PartnershipPage />} />
        {/* <Route path="/press-releases" element={<PressReleasesPage />} /> */}
        <Route path="/fawesome-in-news" element={<PressReleasesPage />} />
        <Route path="*" element={<ErrorPage></ErrorPage>} />
      </Routes>
    </Router>
  );
}

export default App;
