import React from "react";
import { Dropdown } from "react-bootstrap";

function Share({ url, msg }) {
  let shareType = "movies";
  if (url) {
    if (url.indexOf("/blogs/") > -1) {
      shareType = "blogs";
    } else if (url.indexOf("/shows/") > -1) {
      shareType = "shows";
    } else if (url.indexOf("/movies/") > -1) {
      shareType = "movies";
    }
    console.log("Share ,, ", shareType);
  }
  return navigator.share && isMobileOrTablet() ? (
    <>
      <button
        type="button"
        className={`btn btn-btn btn-primary read-more-btn gtm-share-${shareType}`}
        onClick={() => {
          const shareData = {
            title: "Watch Now On Fawesome",
            text: "Watch Now On Fawesome",
            url: replaceWithDomain(url),
          };
          // Use Web Share API for mobile/tablet
          navigator
            .share(shareData)
            .then(() => console.log("Shared successfully"))
            .catch((error) => console.log("Error sharing:", error));
        }}
      >
        &nbsp;
        <svg
          width="22"
          height="15"
          viewBox="0 0 23 21"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M22.9936 1.05269C23 0.976165 22.9936 0.899639 22.9871 0.823113C22.9871 0.803981 22.9806 0.78485 22.9742 0.765718C22.9612 0.708324 22.9418 0.644552 22.916 0.587158C22.9095 0.568026 22.903 0.555272 22.8966 0.53614C22.8642 0.472369 22.8254 0.408597 22.7802 0.351203C22.7802 0.351203 22.7737 0.344826 22.7673 0.338448C22.7673 0.338448 22.7673 0.338448 22.7673 0.332071C22.7608 0.325694 22.7479 0.319317 22.7414 0.31294C22.6961 0.261922 22.6444 0.217282 22.5927 0.185397C22.5733 0.172642 22.5603 0.159888 22.5409 0.147134C22.4827 0.115248 22.4246 0.0833619 22.3664 0.0642304C22.3534 0.0642304 22.3405 0.0514761 22.3276 0.0450989C22.2564 0.0259674 22.1853 0.0132131 22.1077 0.00683594C22.0818 0.00683594 22.056 0.00683594 22.0301 0.00683594C21.9719 0.00683594 21.9137 0.00683594 21.8555 0.0259674C21.8426 0.0259674 21.8297 0.0259674 21.8167 0.0259674L0.750104 4.92363C0.349205 5.01929 0.0517631 5.34452 0.00650023 5.74628C-0.0387626 6.14804 0.174619 6.53705 0.543188 6.70923L9.55696 11.0457L13.3914 20.401C13.5401 20.7645 13.8957 21.0005 14.2902 21.0005C14.6846 21.0005 15.0402 20.7645 15.189 20.401L22.9354 1.30778C22.9354 1.30778 22.9354 1.28865 22.9418 1.28227C22.9677 1.21212 22.9871 1.13559 23 1.05907L22.9936 1.05269ZM18.1375 2.8383L10.1842 9.22184L3.84091 6.16718L18.1375 2.8383ZM14.2837 17.4739L11.4839 10.6439L19.7411 4.02445L14.2837 17.4803V17.4739Z"
            fill="white"
          ></path>
        </svg>
        &nbsp;&nbsp;&nbsp;Share&nbsp;
      </button>
    </>
  ) : (
    <Dropdown>
      <Dropdown.Toggle
        variant={`btn btn-primary read-more-btn gtm-share-${shareType}`}
        id="dropdown-basic"
      >
        &nbsp;
        <svg
          width="22"
          height="15"
          viewBox="0 0 23 21"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M22.9936 1.05269C23 0.976165 22.9936 0.899639 22.9871 0.823113C22.9871 0.803981 22.9806 0.78485 22.9742 0.765718C22.9612 0.708324 22.9418 0.644552 22.916 0.587158C22.9095 0.568026 22.903 0.555272 22.8966 0.53614C22.8642 0.472369 22.8254 0.408597 22.7802 0.351203C22.7802 0.351203 22.7737 0.344826 22.7673 0.338448C22.7673 0.338448 22.7673 0.338448 22.7673 0.332071C22.7608 0.325694 22.7479 0.319317 22.7414 0.31294C22.6961 0.261922 22.6444 0.217282 22.5927 0.185397C22.5733 0.172642 22.5603 0.159888 22.5409 0.147134C22.4827 0.115248 22.4246 0.0833619 22.3664 0.0642304C22.3534 0.0642304 22.3405 0.0514761 22.3276 0.0450989C22.2564 0.0259674 22.1853 0.0132131 22.1077 0.00683594C22.0818 0.00683594 22.056 0.00683594 22.0301 0.00683594C21.9719 0.00683594 21.9137 0.00683594 21.8555 0.0259674C21.8426 0.0259674 21.8297 0.0259674 21.8167 0.0259674L0.750104 4.92363C0.349205 5.01929 0.0517631 5.34452 0.00650023 5.74628C-0.0387626 6.14804 0.174619 6.53705 0.543188 6.70923L9.55696 11.0457L13.3914 20.401C13.5401 20.7645 13.8957 21.0005 14.2902 21.0005C14.6846 21.0005 15.0402 20.7645 15.189 20.401L22.9354 1.30778C22.9354 1.30778 22.9354 1.28865 22.9418 1.28227C22.9677 1.21212 22.9871 1.13559 23 1.05907L22.9936 1.05269ZM18.1375 2.8383L10.1842 9.22184L3.84091 6.16718L18.1375 2.8383ZM14.2837 17.4739L11.4839 10.6439L19.7411 4.02445L14.2837 17.4803V17.4739Z"
            fill="white"
          ></path>
        </svg>
        &nbsp;&nbsp;&nbsp;Share&nbsp;
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <Dropdown.Item
          href={`https://www.facebook.com/sharer/sharer.php?u=${replaceWithDomain(
            url
          )}`}
          target="_blank"
          className={`gtm-fb-share-${shareType}`}
        >
          <li className="ssha ssfb social-link">Facebook</li>
        </Dropdown.Item>
        <Dropdown.Item
          href={`https://twitter.com/intent/tweet?url=${replaceWithDomain(
            url
          )}&text=${msg}`}
          target="_blank"
          className={`gtm-x-share-${shareType}`}
        >
          <li className="ssha ssx social-link">X</li>
        </Dropdown.Item>
        <Dropdown.Item
          href={`https://www.linkedin.com/sharing/share-offsite/?url=${replaceWithDomain(
            url
          )}`}
          target="_blank"
          className={`gtm-linkedin-share-${shareType}`}
        >
          <li className="ssha sslin social-link">LinkedIn</li>
        </Dropdown.Item>
        <Dropdown.Item
          href={`https://api.whatsapp.com/send?text=${msg}:%20${replaceWithDomain(
            url
          )}`}
          target="_blank"
          className={`gtm-whatsppp-share-${shareType}`}
        >
          <li className="ssha sswha social-link">WhatsApp</li>
        </Dropdown.Item>
        <Dropdown.Item
          onClick={() => {
            navigator.clipboard
              .writeText(replaceWithDomain(url))
              .then(() => {});
          }}
          as="button"
          className={`gtm-copylink-share-${shareType}`}
        >
          <li className="ssha sscopylink social-link">Copy Link</li>
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
}

function replaceWithDomain(path) {
  if (path.indexOf("./") > -1) {
    const currentDomain = window.location.origin;
    return path.replace("./", `${currentDomain}/`);
  }
  return path;
}
function isMobileOrTablet() {
  return /Mobi|Android|iPhone|iPad|iPod|Tablet/i.test(navigator.userAgent);
}
export default Share;
