import AppInstall from "./AppInstall";
import Footer from "./Footer";
import UserConsent from "./UserConsent";
import LoaderAnim from "./LoaderAnim";
import React, { useState, useEffect } from "react";
import BlogHeroSection from "./BlogHeroSection";
import BlogDetailsHero from "./BlogDetailsHero";
import BlogList from "./BlogList";
import { useParams } from "react-router";
import BlogDetailsPage from "./BlogDetailsPage";
import PressReleaseList from "./PressReleaseList";

function PressReleases() {
  const { id } = useParams();
  const [listData, setListData] = useState({});
  useEffect(() => {
    /*fetch(`./assets/data/press-release.json?r=${Date.now()}`, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (myJson) {
        const getDetails = myJson.filter((val) => val.id == id);
        setListData(getDetails[0]);
      });*/
  }, []);

  return (
    <React.Fragment>
      <div className="page">
        <AppInstall></AppInstall>
        <BlogHeroSection
          title={"In The News"}
          subTitle={""}
          bgImage={"./assets/images/bannerpress2.webp"}
          showCorLeft={false}
        />
        <PressReleaseList
          json={"./assets/data/press_release_media_fawesome_sorted.json"}
        />
        <Footer></Footer>
        <UserConsent></UserConsent>
      </div>
      <LoaderAnim id="wanim"></LoaderAnim>
    </React.Fragment>
  );
}

const wait = (delay = 0) =>
  new Promise((resolve) => setTimeout(resolve, delay));

const setVisible = (elementOrSelector, visible) => {
  let element =
    typeof elementOrSelector === "string"
      ? document.querySelector(elementOrSelector)
      : elementOrSelector;
  if (element) {
    element.style.display = visible ? "block" : "none";
  }
};

wait(1).then(() => {
  setVisible(".page", false);
  setVisible("#loading", true);
});

document.addEventListener("DOMContentLoaded", () =>
  wait(2000).then(() => {
    setVisible(".page", true);
    setVisible("#loading", false);
  })
);
export default PressReleases;
