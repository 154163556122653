import FavouriteGenresInview from "./FavouriteGenresInview";
import NoSubscriptions from "./NoSubscriptions";
import TilesSliderInView from "./TilesSliderInView";
// import TrailerInView from "./TrailerInView";
import TrailerSliderInView from "./TrailerSliderInView";
import HalloweenSliderInView from "./HalloweenSliderInView";
import WatchEveryWhereTab from "./WatchEveryWhereTab";
import AppInstall from "./AppInstall";
// import Footer from "./Footer";
import FooterInView from "./FooterInView";
import UserConsent from "./UserConsent";
import LoaderAnim from "./LoaderAnim";
import React from "react";
import HeroSection from "./HeroSection";
import FAQ from "./FAQ";
import AboutFTI from "./AboutFTI";

import BlogTilesInview from "./BlogTilesInview";

function Home() {
  const faqData = [
    {
      name: "What is Fawesome?",
      answer:
        "Fawesome is a free movies website offering a vast selection of movies and TV shows. You can watch free movies online across various genres, from action to comedy, all without any subscription fees.",
    },
    {
      name: "Is Fawesome legal?",
      answer:
        "Yes, it’s 100% legal. Our content includes ads while making sure that you get to watch the best content out there.",
    },
    {
      name: "How can I watch free movies on Fawesome?",
      answer:
        "To watch free movies on Fawesome, simply visit our website or install the free movies app. Browse through our extensive collection and start streaming your favorite titles instantly.",
    },
    {
      name: "Do I need to create an account to watch movies for free?",
      answer:
        "No, you don’t need to create an account to watch free movies on Fawesome. Just visit our free movie website and start streaming your favorite movies and TV shows instantly.",
    },
    {
      name: "What kind of movies can I watch on Fawesome?",
      answer:
        "Fawesome offers a diverse selection of movies, including action, drama, comedy, horror, and more. Our collection includes both classic and contemporary titles, all available as free online movies.",
    },
    {
      name: "Can I watch TV shows for free on Fawesome?",
      answer:
        "Yes, Fawesome not only offers free movies to watch but also a great selection of TV shows. You can watch TV shows online free with high-quality streaming on our platform.",
    },
    {
      name: "How often is new content added to Fawesome?",
      answer:
        "We regularly update our library with new free movies online and TV shows. Keep checking back for the latest releases and new additions to our free movie streaming sites list.",
    },
    {
      name: "Can I watch free movies on my mobile device?",
      answer:
        "Yes, you can watch movies free online on any device, including smartphones and tablets. Install the Fawesome app to enjoy seamless streaming of free movies and TV shows on the go.",
    },
    {
      name: "What are the best free movies on Fawesome right now?",
      answer:
        "Check out our “Fawesome This Week” section to find the most popular free movies to watch. We update this regularly, so you can stay up-to-date with what’s hot on our free movie websites.",
    },
  ];
  return (
    <React.Fragment>
      <div className="page">
        <AppInstall></AppInstall>
        <HeroSection></HeroSection>
        <WatchEveryWhereTab></WatchEveryWhereTab>
        <NoSubscriptions></NoSubscriptions>
        <TilesSliderInView
          title={"Trending Titles This Month"}
          json={"./assets/data/slider-items.json"}
        ></TilesSliderInView>
        {/* <TilesSliderInView
          title={"Black History Month with Stories That Matter"}
          json={"./assets/data/slider-items-2.json"}
        ></TilesSliderInView> */}
        {/* <HalloweenSliderInView></HalloweenSliderInView> */}
        <TrailerSliderInView></TrailerSliderInView>
        {/* <TrailerInView></TrailerInView> */}

        <BlogTilesInview></BlogTilesInview>
        <FavouriteGenresInview />
        <AboutFTI></AboutFTI>
        <FAQ data={faqData}></FAQ>
        <FooterInView></FooterInView>
        <span className="m-1">&nbsp;&nbsp;&nbsp;&nbsp;</span>
        <UserConsent></UserConsent>
      </div>
      <LoaderAnim id="wanim"></LoaderAnim>
    </React.Fragment>
  );
}

const wait = (delay = 0) =>
  new Promise((resolve) => setTimeout(resolve, delay));

const setVisible = (elementOrSelector, visible) => {
  let element =
    typeof elementOrSelector === "string"
      ? document.querySelector(elementOrSelector)
      : elementOrSelector;
  if (element) {
    element.style.display = visible ? "block" : "none";
  }
  /*(typeof elementOrSelector === "string"
    ? document.querySelector(elementOrSelector)
    : elementOrSelector
  ).style.display = visible ? "block" : "none";*/
};

wait(1).then(() => {
  setVisible(".page", false);
  setVisible("#loading", true);
  /*try {
    setVisible(".page", false);
  } catch (error) {}
  try {
    setVisible("#loading", true);
  } catch (error) {}*/
});

document.addEventListener(
  "DOMContentLoaded",
  () => {
    setVisible(".page", true);
    setVisible("#loading", false);
  }
  // wait(2000).then(() => {
  //   setVisible(".page", true);
  //   setVisible("#loading", false);
  // })
);
export default Home;
