import React from "react";
import Share from "./Share"; // Import the Share component

function DescriptionRenderer({ listData }) {
  // Split the description by the placeholder and map over the parts
  const description = listData.description;
  const links = listData.links;
  const parts = description.split("${add_share_component}");

  return (
    <div>
      {parts.map((part, index) => (
        <React.Fragment key={index}>
          {/* Render each HTML section */}
          <span dangerouslySetInnerHTML={{ __html: part }} />

          {/* Add Share component after every split, except for the last part */}
          {index < parts.length - 1 && (
            <div className="share-container blog-list">
              <Share url={links[index]} msg={"Watch%20Now%20On%20Fawesome"} />
            </div>
          )}
        </React.Fragment>
      ))}
    </div>
  );
}

export default DescriptionRenderer;
